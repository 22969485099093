<template>
</template>
<script>
import {mapGetters, mapActions} from 'vuex';
import api from "src/api/index";
export default {
    name: 'Token',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: ''
    },
    computed: {
        ...mapGetters(['loggedInUser', 'error'])
    },
    watch: {
        loggedInUser(val) {
            if (val && val.token && val.token.length > 0) {
                // this.makeToast("success", "Successfully Logged In");
                console.log('logged in successfully ')
                this.loading = true
                setTimeout(() => {
                    this.$router.push('/')
                }, 200)
            }
        },
        error(val) {            
            if (val != null) {
                this.error = val.message;                
            }
        }
    },
    methods: {
        ...mapActions(['login']),
    },
    created() {
        this.login({
            username: this.$route.params.code, 
            password: this.$route.params.code
        });        
    },

}
</script>